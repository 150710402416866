import { Button, FormContainer, Input } from "../../../components";
import { FormGroupType } from ".";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { AlunoCurso } from "../../../models";

export type Step2Props = {
  matricula?: string;
  form: FormGroupType;
  events: {
    onReturn: () => any;
    onPreviewContract: () => any;
    onSendContract: () => any;
    onSave: () => any;
    onAddPaymentDate: (i: number, alunoCurso: Partial<AlunoCurso>) => any;
    onRemovePaymentDate: (i: number, alunoCurso: Partial<AlunoCurso>) => any;
    onAddClassDate: (i: number, alunoCurso: Partial<AlunoCurso>) => any;
    onRemoveClassDate: (i: number, alunoCurso: Partial<AlunoCurso>) => any;
  }
}

export default function Step2(props: Step2Props) {


  return (
    <FormContainer title="Definir datas">
      {props.form.form.aluno_cursos!.map((alunoCurso, i) => (
        <div key={i}>
          <h1 className="bg-slate-200 rounded p-2 mb-2">{`${alunoCurso.aluno?.nome} - ${alunoCurso.curso?.descricao}`}</h1>
          <h2 className="mt-1 mb-2 font-bold">Datas dos pagamentos</h2>
          <div className="grid md:grid-cols-8 gap-4">
            {alunoCurso.meses_pagamento?.map((data, i) => (
              <Input 
                label={'Pagamento ' + (i + 1)}
                key={'Pagamento ' + (i + 1)}
                type="date"
                value={data}
                disabled={!!props.matricula}
                setValue={(v) => {
                  const newMesesPagamento = alunoCurso.meses_pagamento;
                  newMesesPagamento![i] = v;
                  props.form.setAlunoCurso(i, { ...alunoCurso, meses_pagamento: newMesesPagamento  });
                }}
              />
            ))}

            {!props.matricula && <div className="flex  gap-2 items-center justify-center ">
              <button className="hover:animate-pulse" type="button" onClick={() => props.events.onRemovePaymentDate(i, alunoCurso)}>
                <FiMinusCircle color={'#FF6666'} size={30}  />
              </button>
              <button className="hover:animate-pulse" type="button" onClick={() => props.events.onAddPaymentDate(i, alunoCurso)}>
                <FiPlusCircle color={'#30694A'} size={30}/>
              </button>
            </div>}
          </div>
          <br />

          <h2 className="mt-1 font-bold">Datas das aulas</h2>
          {alunoCurso.datas_aulas_promise ? (
            <span className="text-slate-500">Carregando...</span>
          ) : (
            <div className="grid md:grid-cols-8 gap-4">
              {alunoCurso.datas_aulas?.map((data, i) => (
                <Input 
                  label={'Aula ' + (i + 1)}
                  tooltip={data.title}
                  key={`date-${i}`}
                  type="date"
                  disabled={!!props.matricula}
                  value={data.date}
                  setValue={(v) => {
                    const newDatasAulas = alunoCurso.datas_aulas;
                    newDatasAulas![i].date = v;
                    props.form.setAlunoCurso(i, { ...alunoCurso, datas_aulas: newDatasAulas  });
                  }}
                />
              ))}

              {!props.matricula && <div className="flex  gap-2 items-center justify-center ">
                <button className="hover:animate-pulse" type="button" onClick={() => props.events.onRemoveClassDate(i, alunoCurso)}>
                  <FiMinusCircle color={'#FF6666'} size={30}  />
                </button>
                <button className="hover:animate-pulse" type="button" onClick={() => props.events.onAddClassDate(i, alunoCurso)}>
                  <FiPlusCircle color={'#30694A'} size={30}/>
                </button>
              </div>}
            </div>
          )}

          <br /><br />
        </div>
      ))}
      
      <footer className="flex items-center justify-between mt-10">
        <div></div>

        <div className="flex gap-4">
          <Button
            loading={props.form.isLoading} 
            type="button"
            onClick={props.events.onReturn}
          >
            Voltar
          </Button>

          <Button
            loading={props.form.isLoading} 
            type="button"
            onClick={props.events.onPreviewContract}
          >
            Pré-visualizar contrato
          </Button>

          {!props.matricula ? (
            <Button type="button" onClick={props.events.onSave} variant="confianca">
              Gerar Matrícula
            </Button>
          ) : (
            <Button type="button" onClick={props.events.onSendContract} variant="confianca">
              Enviar Contrato
            </Button>
          )}
        </div>
      </footer>
    </FormContainer>
  );
}
