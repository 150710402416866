import { FiPlusCircle } from "react-icons/fi";
import { Button, FormContainer } from "../../../components";
import MatriculaAlunoForm from "../../../components/form/MatriculaAlunoForm";
import { Aluno, Curso } from "../../../models";
import { FormGroupType } from ".";

export type Step1Props = {
  matricula?: string;
  aluno: { loading?: boolean; alunos: Aluno[]; responsavelCPF?: string; };
  curso: { loading?: boolean; cursos: Curso[]; };
  form: FormGroupType;
  events: {
    onNext: () => any;
  }
}

export default function Step1(props: Step1Props) {
  return (
    <FormContainer title="Cadastrar cursos para aluno" onSubmit={props.events.onNext}>
      <div className="grid gap-6 md:grid-cols-2">
        {(props.form.form.aluno_cursos ?? []).map((alunoCurso, i) => (
          <MatriculaAlunoForm 
            key={i}
            matricula={props.matricula}
            alunoCursoIndex={i}
            alunoCurso={alunoCurso}
            errors={props.form.getAlunoCursoErrors(i)}
            setAlunoCurso={props.form.setAlunoCurso}
            aluno={{
              isLoading: props.aluno.loading,
              options: props.aluno.alunos,
              responsavelCPF: i === 0 ? undefined : props.aluno.responsavelCPF,
            }}
            curso={{
              isLoading: props.curso.loading,
              options: props.curso.cursos,
            }}
          />
        ))}

        {!props.matricula && <div className="flex flex-col items-center justify-center ">
          <button className="flex flex-col items-center justify-center hover:animate-pulse" type="button" onClick={() => props.form.addAlunoCurso() }>
            <FiPlusCircle color={'#30694A'} size={30}/>
            <span>Adicionar Curso</span>
          </button>
        </div>}
      </div>

      <footer className="flex items-center justify-between mt-10">
        <div>
          <small>Campos com <span className="text-red-500">*</span> são obrigatórios</small>
        </div>

        <Button
          loading={props.form.isLoading} 
          variant="confianca"
          type="submit"
        >
          Próximo
        </Button>
      </footer>
    </FormContainer>
  );
}
