/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { eventoApi, intensivoApi } from '../../services';
import { useCursos } from '../useCursos';
import useForm from '../useForm';
import Alert from '../../helpers/Alert';
import { z } from 'zod';
import { Evento } from '../../models';

const rules = z.object({
  nome: z.string().min(1),
  cor: z.string().min(1),
  inicio: z.string().min('XXXX-XX-XX'.length, 'Data Inválida'),
  fim: z.string().min('XXXX-XX-XX'.length, 'Data Inválida'),
});

export type UseEventoForm = {
  id?: number | string;
  updateFunction?: () => any;
}

type FormType = Evento;

export function useEventoForm(props?: UseEventoForm) {
  const [key, setKey] = useState<number>(1);
  const { cursos, loading: loadingCursos, cursosAsSelectOptions }  = useCursos();
  const { form, setForm, errors, register, registerList, setLoading, loading, validateWithZod } = useForm<FormType>({
    loader: intensivoApi.one as any,
  });

  useEffect(() => {
    props?.id ? load(props?.id) : setForm({});
  }, [props?.id]);

  async function save() {
    if (!validateWithZod(rules)) return;
    setLoading(true);
    const response = await eventoApi.save({
      ...form,
      evento_cursos: form.evento_cursos?.filter(eventoCurso => eventoCurso.curso_id),
    });
    setLoading(false);
    if (response.isError) {
        return Alert.error(response.data.message || 'Falha ao salvar evento');
    }
    Alert.success('Aluno salvo com sucesso');
    props?.updateFunction && props?.updateFunction();
  }

  async function destroy() {
    const confirm = await Alert.confirm('Deseja Excluir?', 'Tem certeza que deseja excluir?');
    if (! confirm) return;
    Alert.await('Excluindo...');
    const response = await eventoApi.destroy(form.id as any);
    Alert.close();
    if (response.isError) {
      Alert.error(response.data.message || 'Falha ao excluir');
      return;
    }
    Alert.success('Excluído com sucesso');
    props?.updateFunction && props?.updateFunction();
  }

  async function load(id: number | string) {
    setLoading(true);
    const { data, isError } = await eventoApi.one(id as any);
    setLoading(false);
    if (isError) {
      return Alert.error(data.message || 'Falha ao carregar evento');
    }
    setForm(data);
  }

  function addCurso() {
    setForm({
      ...form,
      evento_cursos: [
        ...(form.evento_cursos ?? []),
        {} as any,
      ]
    });
  }

  function removeCurso(index: number) {
    setForm({
      ...form,
      evento_cursos: [
        ...form.evento_cursos!.slice(0, index),
        ...form.evento_cursos!.slice(index + 1)
      ]
    });
  }

  return {
    form,
    setForm,
    destroy,
    save,
    loading,
    errors, 
    register,
    registerList,
    load,
    key,
    setKey,
    cursos: {
      data: cursos,
      asSelectOptions: cursosAsSelectOptions,
      loading: loadingCursos,
      add: addCurso,
      remove: removeCurso,
    },
  }
}
