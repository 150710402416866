import { useQuery } from "react-query";
import { Aluno } from "../../models";
import { alunoApi } from "../../services";

export function useAlunos() {
  const query = useQuery<Aluno[]>('alunos.all', async () => {
    const { data, isError } =  await alunoApi.listAll({
      query: {
      }
    });
    if (isError) throw new Error('Erro ao buscar lista de alunos');
    return data;
  }, {
    initialData: [],
  });

  return {
    alunos: query.data as Aluno[],
    loading: query.isLoading
  }
}
