import { AppRoute } from './index';
import Login from '../pages/Auth/Login';
import RecuperarSenha from '../pages/Auth/RecuperarSenha';
import Dashboard from '../pages/DashBoard';
import Lembretes from '../pages/Lembretes';
import LembretesForm from '../pages/Lembretes/formulario';
import Salas from '../pages/Salas';
import Cursos from '../pages/Cursos';
import Professores from '../pages/Professores';
import FormularioProfessor from '../pages/Professores/Form';
import TabelaValoresCursos from '../pages/Cursos/TabelaValores';
import TabelaHorarios from '../pages/Salas/TabelaHorarios';
import TabelaDisponibilidades from '../pages/Salas/TabelaDisponibilidades';
import Alunos from '../pages/Alunos';
import FormularioAlunos from '../pages/Alunos/Form';
import FormularioAlunosForm from '../pages/AlunosCursos/Form';
import Calendario from '../pages/Calendario';
import Avulsas from '../pages/Avulsas';
import Intensivos from '../pages/Intensivos';
import Vendas from '../pages/Vendas';
import VendasCategorias from '../pages/VendasCategorias';
import VendasClientes from '../pages/VendasClientes';
import Despesas from '../pages/Contas';
import DespesaExtras from '../pages/ContasExtras';
import Repasses from '../pages/Repasses';
import AlunosCursos from '../pages/AlunosCursos';
import AlunosList from '../pages/AlunosList';
import Clausulas from '../pages/Clausulas';

const appRoutes: AppRoute[] =  [
    /*{
        path: '/',
        requiresAuth: false,
        element: <Login />
    },*/
    {
        path: '/login',
        requiresAuth: false,
        element: <Login />
    },
    {
        path: '/recuperar/senha',
        requiresAuth: false,
        element: <RecuperarSenha />
    },
    {
        path: '/lembretes',
        requiresAuth: true,
        element: <Lembretes />
    },
    {
        path: '/lembretes/cadastro',
        requiresAuth: true,
        element: <LembretesForm />
    },
    {
        path: '/lembretes/cadastro/:id',
        requiresAuth: true,
        element: <LembretesForm />
    },
    {
        path: '/salas',
        requiresAuth: true,
        element: <Salas />
    },
    {
        path: '/salas/disponibilidades',
        requiresAuth: true,
        element: <TabelaDisponibilidades />
    },
    {
        path: '/salas/horarios',
        requiresAuth: true,
        element: <TabelaHorarios />
    },
    {
        path: '/cursos',
        requiresAuth: true,
        element: <Cursos />
    },
    {
        path: '/cursos/valores',
        requiresAuth: true,
        element: <TabelaValoresCursos />
    },
    {
        path: '/professores',
        requiresAuth: true,
        element: <Professores />
    },
    {
        path: '/professores/cadastro',
        requiresAuth: true,
        element: <FormularioProfessor />
    },
    {
        path: '/professores/cadastro/:id',
        requiresAuth: true,
        element: <FormularioProfessor />
    },
    {
        path: '/alunos-lista',
        requiresAuth: true,
        element: <AlunosList />
    },
    {
        path: '/alunos',
        requiresAuth: true,
        element: <Alunos />
    },
    {
        path: '/alunos/cadastro',
        requiresAuth: true,
        element: <FormularioAlunos />
    },
    {
        path: '/alunos/cadastro/:id',
        requiresAuth: true,
        element: <FormularioAlunos />
    },
    {
        path: '/alunos-cursos',
        requiresAuth: true,
        element: <AlunosCursos />
    },
    {
        path: '/alunos-cursos/cadastro',
        requiresAuth: true,
        element: <FormularioAlunosForm />
    },
    {
        path: '/alunos-cursos/cadastro/:matricula',
        requiresAuth: true,
        element: <FormularioAlunosForm />
    },
    {
        path: '/calendario',
        requiresAuth: true,
        element: <Calendario />
    },
    {
        path: '/avulsas',
        requiresAuth: true,
        element: <Avulsas />
    },
    {
        path: '/clausulas',
        requiresAuth: true,
        element: <Clausulas />
    },
    {
        path: '/intensivos',
        requiresAuth: true,
        element: <Intensivos />
    },
    {
        path: '/vendas',
        requiresAuth: true,
        element: <Vendas />
    },
    {
        path: '/vendas-categorias',
        requiresAuth: true,
        element: <VendasCategorias />
    },
    {
        path: '/vendas-clientes',
        requiresAuth: true,
        element: <VendasClientes />
    },
    {
        path: '/despesas',
        requiresAuth: true,
        element: <Despesas />
    },
    {
        path: '/despesas-extras',
        requiresAuth: true,
        element: <DespesaExtras />
    },
    {
        path: '/repasses',
        requiresAuth: true,
        element: <Repasses />
    },
    {
        path: '/',
        requiresAuth: true,
        element: <Dashboard />
    },
    {
        path: '/*',
        requiresAuth: false,
        element: <h1>404</h1>
    }
];

export default appRoutes;